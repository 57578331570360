

















































































































































































































































































































































































































































































































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      form: false,
      currentDate: "",
      totalItem: null,
      userManager,
      editedItem: {},
      outstandingMoney: {},
      data: [],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
    };
  },
  watch: {
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
  },
  computed: {
    accumulatedProfit() {
      if (this.outstandingMoney) {
        const inputMoney =
          (this.outstandingMoney.company_account || 0) +
          (this.outstandingMoney.personal_account || 0) +
          (this.outstandingMoney.ndt_equivalent || 0) +
          (this.outstandingMoney.loan || 0) +
          (this.outstandingMoney.loan_guarantee || 0) +
          (this.outstandingMoney.customer_in_debt || 0) +
          (this.outstandingMoney.cash_advance_officer || 0) +
          (this.outstandingMoney.is_different || 0);
        const outputMoney =
          (this.outstandingMoney.debt_bt || 0) +
          (this.outstandingMoney.export_debt || 0) +
          (this.outstandingMoney.import_debt || 0) +
          (this.outstandingMoney.unpaid_wages || 0) +
          (this.outstandingMoney.customer_debt || 0) +
          (this.outstandingMoney.other_debt || 0);
        return inputMoney - outputMoney;
      }
      return 0;
    },
    totalCost() {
      return this.sum([
        this.outstandingMoney.xk,
        this.outstandingMoney.nk,
        this.outstandingMoney.bt,
        this.outstandingMoney.vn,
        this.outstandingMoney.hd,
        this.outstandingMoney.l,
        this.outstandingMoney.hl,
        this.outstandingMoney.bhxh,
        this.outstandingMoney.tdd,
        this.outstandingMoney.tv,
        this.outstandingMoney.ps,
      ]);
    },
  },
  methods: {
    async initialize() {
      await this.getAll();

      const m = moment(this.currentDate);
      const start = m.startOf(this.timeMode).unix();
      const end = m.endOf(this.timeMode).unix();

      this.totalItem = await apiClient.reportGetAll(start, end);

      this.totalItem.calculated = {
        dt:
          this.totalItem.all_packages.fee_transport +
          this.totalItem.all_packages.fee_trust +
          this.totalItem.vn_packages.profit +
          // this.totalItem.all_liquidation_slips.surplusage,
          (this.outstandingMoney.tkdk || 0),
        dt_tl:
          this.totalItem.liquidated_packages.fee_transport +
          this.totalItem.liquidated_packages.fee_trust +
          this.totalItem.liquidated_packages.profit +
          this.totalItem.approved_liquidation_slips.surplusage +
          (this.outstandingMoney.tk || 0) +
          (this.outstandingMoney.cs || 0),
        cp:
          this.totalItem.all_trucks.export_fee_export +
          this.totalItem.all_trucks.export_fee_import +
          this.totalItem.all_trucks.export_fee_transport +
          this.totalItem.all_trucks.export_fee_unload_vn +
          this.totalItem.all_trucks.export_fee_extra,
        salary_ut:
          this.totalItem.all_packages.salary_dsvc +
          this.totalItem.all_virtual_packages.profit * 0.2 +
          this.totalItem.all_packages.fee_transport * 0.007 +
          this.totalItem.all_virtual_packages.profit * 0.1,
        salary_tl:
          this.totalItem.liquidated_packages.salary_dsvc +
          this.totalItem.liquidated_packages.profit * 0.2 +
          this.totalItem.liquidated_packages.fee_transport * 0.007 +
          this.totalItem.liquidated_packages.profit * 0.1,
      };
      this.totalItem.calculated.ln_ut =
        this.totalItem.calculated.dt - this.totalItem.calculated.cp;
      this.totalItem.calculated.ln_tl =
        this.totalItem.calculated.dt_tl - this.totalItem.calculated.cp;
    },
    async getAll() {
      const filters = [];
      const m = moment(this.currentDate);
      const start = m.startOf(this.timeMode).unix();
      const end = m.endOf(this.timeMode).unix();
      if (start) {
        filters.push({
          key: "created_time",
          operator: ">=",
          value: start,
        });
      }
      if (end) {
        filters.push({
          key: "created_time",
          operator: "<=",
          value: end,
        });
      }
      const { items } = await apiClient.outstandingMoneyGetAll({
        rawOptions: {
          limit: -1,
        },
        filters,
      });
      if (this.timeMode === "year") {
        const totalItem = {};
        let firstItem = true;
        for (const i in items) {
          const item = items[i];
          for (const k in item) {
            const v = item[k];
            if (
              !["id", "created_at", "updated_at", "created_time"].includes(k) &&
              !isNaN(v)
            ) {
              if (
                [
                  "company_account",
                  "personal_account",
                  "ndt_equivalent",
                  "loan",
                  "loan_guarantee",
                  "customer_in_debt",
                  "cash_advance_officer",
                  "is_different",
                  "debt_bt",
                  "export_debt",
                  "import_debt",
                  "unpaid_wages",
                  "customer_debt",
                  "other_debt",
                ].includes(k)
              ) {
                if (firstItem) {
                  totalItem[k] = v;
                }
              } else {
                if (!totalItem[k]) {
                  totalItem[k] = 0;
                }
                totalItem[k] += v;
              }
            }
          }
          firstItem = false;
        }
        this.outstandingMoney = totalItem;
      } else {
        if (items && items[0]) {
          this.outstandingMoney = items[0];
        } else {
          this.outstandingMoney = {};
        }
      }
    },
    async save() {
      const params = { ...this.editedItem, ...{ created_time: 0 } };
      for (const paramsKey in params) {
        if (!params[paramsKey]) {
          params[paramsKey] = 0;
        }
        if (paramsKey === "created_time") {
          params[paramsKey] = moment(this.currentDate).unix();
        }
      }
      try {
        if (!params.id) {
          const r = await apiClient.outstandingMoneysCreate(params);
          console.log(r);
        } else {
          await apiClient.outstandingMoneyUpdate(params);
        }
      } catch (e) {
        console.log(e);
      } finally {
        // await this.reset();
        // await this.getAll();
        location.reload();
      }
    },
    reset() {
      this.dialog = false;
      this.editedItem = {};
    },
    edit() {
      this.editedItem = { ...this.outstandingMoney };
    },
    sum(args) {
      let value = 0;
      for (const arg of args) {
        if (arg && !isNaN(arg)) {
          value += arg;
        }
      }
      return value;
    },
    revenuePercent(value) {
      if (isNaN(value)) {
        value = 0;
      }
      let p = (value * 100) / this.totalItem.calculated.dt;
      p = Math.round(p * 10) / 10;
      if (isNaN(p)) {
        p = 0;
      }
      return p;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
    // this.currentDate = "2021-11";
  },
});
